import { ShopifySellingPlanGroupsEntity, ShopifySellingPlansEntity } from './types/shopify';

export const money = (val: number, currency: string) =>
  val === null
    ? ''
    : new Intl.NumberFormat(navigator.language, {
        style: 'currency',
        currency
      }).format(val / 100);

export const percentage = val => `${val}%`;

export const DEFAULT_PAY_AS_YOU_GO_GROUP_NAME = 'Subscribe and Save';
const EXPERIMENT_SHOPIFY_APP_ID_PREFIX = 'ordergroove-subscribe-and-save-';

// returns the non-prepaid OG selling plan to use for displaying frequencies
export const getPayAsYouGoSellingPlanGroup = (sellingPlanGroups: ShopifySellingPlanGroupsEntity[] = []) => {
  // retrieve an OG Default or PSFL Selling Plan Group, preferring to return PSFL groups if they exist
  const productSpecificFrequencySellingPlanGroup = sellingPlanGroups.find(isProductSpecificFrequencySellingPlanGroup);

  return (
    productSpecificFrequencySellingPlanGroup ||
    sellingPlanGroups.find(isDefaultSellingPlanGroup) ||
    sellingPlanGroups.find(isExperimentSellingPlanGroup)
  );
};

// returns OG selling plan groups that are not prepaid
export const getPayAsYouGoSellingPlanGroups = (sellingPlanGroups: ShopifySellingPlanGroupsEntity[] = []) => {
  return sellingPlanGroups.filter(
    group =>
      isDefaultSellingPlanGroup(group) ||
      isProductSpecificFrequencySellingPlanGroup(group) ||
      isExperimentSellingPlanGroup(group)
  );
};

const isDefaultSellingPlanGroup = (group: ShopifySellingPlanGroupsEntity) =>
  // we need to check name or app_id - the app_id is newer and not all selling plans have it
  // the default group name is only applied to the default selling plan group
  // flex incentives selling plan groups have a different name but the same subscribe-and-save app ID
  group.name === DEFAULT_PAY_AS_YOU_GO_GROUP_NAME || group.app_id === 'ordergroove-subscribe-and-save';

export const isProductSpecificFrequencySellingPlanGroup = (group: ShopifySellingPlanGroupsEntity) =>
  group.name.startsWith('og_psfl') || group.app_id === 'ordergroove-product-specific-frequency-list';

export const isExperimentSellingPlanGroup = (group: ShopifySellingPlanGroupsEntity) =>
  // @ts-expect-error 18047
  group.app_id?.startsWith(EXPERIMENT_SHOPIFY_APP_ID_PREFIX);

export const getPayAsYouGoSellingPlan = (sellingPlans: ShopifySellingPlansEntity[]) => {
  const group = getPayAsYouGoSellingPlanGroup(sellingPlans.map(plan => plan.group));
  return sellingPlans.find(plan => plan.group === group);
};
